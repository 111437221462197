import * as R from 'ramda';

import { assetScannerIndexedDBStores } from './indexedDbStores.js';

const DB_INDEX_SUFFIX = '_IDX';

const ASSET_SCANNER_INDEXED_DB_NAME = 'assetScanner';

// increase version to create new store for indexes
const ASSET_SCANNER_INDEXED_DB_VERSION = 3;

const createObjectStore =
  (db) =>
  ({ name, keyPath, indexes }) => {
    let objectStore = null;

    if (!db.result.objectStoreNames.contains(name)) {
      objectStore = db.result.createObjectStore(name, {
        keyPath,
      });
    } else {
      objectStore = db.transaction.objectStore(name);
    }

    if (indexes && objectStore) {
      indexes.forEach(({ fieldName, options }) => {
        const indexName = `${fieldName}${DB_INDEX_SUFFIX}`;
        if (!objectStore.indexNames.contains(indexName)) {
          objectStore.createIndex(indexName, fieldName, options);
        }
      });
    }
  };

function promisify(request) {
  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve(request.result);

    request.onerror = () => reject(request.error);
  });
}
// getObsoleteStores :: ([String], [{name: String}]) => [String]
const getObsoleteStores = (allStores, storesConfig) =>
  R.compose(
    R.difference(R.defaultTo([], allStores)),
    R.pluck('name'),
  )(storesConfig);

function createIndexedDbBase(dbName, stores, version) {
  const db = indexedDB.open(dbName, version);

  db.onupgradeneeded = (event) => {
    const database = event.target;
    stores.forEach(createObjectStore(database));

    const obsoleteStores = getObsoleteStores(
      event.target.result.objectStoreNames,
      stores,
    );

    obsoleteStores.forEach((name) => db.result.deleteObjectStore(name));
  };

  return promisify(db);
}

const createIndexedDb = (dbName, stores, version) => async () => {
  const assetScannerDb = await createIndexedDbBase(dbName, stores, version);

  const createTransaction = (storeName, mode) =>
    assetScannerDb.transaction(storeName, mode).objectStore(storeName);

  const create = (storeName, item) => {
    const store = createTransaction(storeName, 'readwrite');
    return promisify(store.add(item));
  };

  const createMany = (storeName, items) => {
    const store = createTransaction(storeName, 'readwrite');
    return Promise.all(items.map((item) => promisify(store.add(item))));
  };

  const getAll = (storeName) => {
    const store = createTransaction(storeName, 'readonly');
    return promisify(store.getAll());
  };

  const getById = (storeName, id) => {
    const store = createTransaction(storeName, 'readonly');
    return promisify(store.get(id));
  };

  const clearStore = (storeName) => {
    const store = createTransaction(storeName, 'readwrite');
    return promisify(store.clear());
  };

  const update = async (storeName, newFields, id) => {
    const store = createTransaction(storeName, 'readwrite');
    const oldItem = await promisify(store.get(id));
    return promisify(store.put({ ...oldItem, ...newFields }));
  };

  const getByIndex = (storeName, fieldName, keyRange) => {
    const store = createTransaction(storeName, 'readonly');
    return promisify(
      store.index(`${fieldName}${DB_INDEX_SUFFIX}`).getAll(keyRange),
    );
  };

  const createOrUpdateMany = (storeName, items) => {
    const store = createTransaction(storeName, 'readwrite');
    return Promise.all(items.map((item) => promisify(store.put(item))));
  };

  const createOrUpdate = (storeName, item) => {
    const store = createTransaction(storeName, 'readwrite');
    return promisify(store.put(item));
  };

  const getByIndexCount = (storeName, fieldName, keyRange) => {
    const store = createTransaction(storeName, 'readonly');
    return promisify(
      store.index(`${fieldName}${DB_INDEX_SUFFIX}`).count(keyRange),
    );
  };

  const deleteManyByIds = (storeName, ids) => {
    const store = createTransaction(storeName, 'readwrite');
    return Promise.all(ids.map((id) => promisify(store.delete(id))));
  };

  const deleteById = (storeName, id) => {
    const store = createTransaction(storeName, 'readwrite');
    return promisify(store.delete(id));
  };

  return {
    create,
    getAll,
    getById,
    clearStore,
    update,
    getByIndex,
    createMany,
    createOrUpdateMany,
    createOrUpdate,
    getByIndexCount,
    deleteManyByIds,
    deleteById,
  };
};

export const assetScannerIndexedDb = createIndexedDb(
  ASSET_SCANNER_INDEXED_DB_NAME,
  assetScannerIndexedDBStores,
  ASSET_SCANNER_INDEXED_DB_VERSION,
);
