import * as R from 'ramda';
import { debounce } from 'poly-utils';

// used to store temporal access token instead of session storage
// which can be cleared suddenly without any reason
let GLOBAL_VARIABLE_FOR_TEMPORAL_USER_TOKEN = null;

// used to store last subscription closed code to handle retry correctly
let GLOBAL_VARIABLE_FOR_LAST_CLOSE_CODE = null;

// subscription closed account disable code
const WS_SUBSCRIPTION_ACCOUNT_DISABLED_CODE = 4403;

// getTemporalUserToken :: _ -> Token
export const getTemporalUserToken = () =>
  GLOBAL_VARIABLE_FOR_TEMPORAL_USER_TOKEN;

// setTemporalUserToken :: Token -> _
export const setTemporalUserToken = (token) => {
  GLOBAL_VARIABLE_FOR_TEMPORAL_USER_TOKEN = token;
};

// getLastClosedConnectionCode :: _ -> Int
const getLastClosedConnectionCode = () => GLOBAL_VARIABLE_FOR_LAST_CLOSE_CODE;

// setLastClosedConnectionCode :: Int -> _
export const setLastClosedConnectionCode = (code) => {
  GLOBAL_VARIABLE_FOR_LAST_CLOSE_CODE = code;
};

// shouldRetryWSLinkSubscription :: Function -> { type: String } -> Boolean
export const shouldRetryWSLinkSubscription =
  (handleApplicationMaintenance) =>
  ({ type }) => {
    // debounce function to avoid multiple processing on batch queries
    const processErrors = debounce(50)(() => {
      if (type === 'error' && R.is(Function)(handleApplicationMaintenance)) {
        handleApplicationMaintenance();
      }
    });

    const clearConnectionCodeDebounced = debounce(50)(() => {
      setLastClosedConnectionCode(null);
    });

    const code = getLastClosedConnectionCode();

    if (type === 'close' && code === WS_SUBSCRIPTION_ACCOUNT_DISABLED_CODE) {
      clearConnectionCodeDebounced();
      return false;
    }

    processErrors();

    return true;
  };
